.modal {
    padding      : 1rem;
    border       : none;
    background   : none;
    width        : 100%;
    max-width    : 800px;
    box-sizing   : border-box;
    border-radius: 8px;
    position     : relative;
    overflow     : visible;
    max-height   : 100%;
}

.modal:focus {
    outline: none;
}

.modal_wraper {
    border-radius: 8px;
    border       : 1px solid #dcdcdc;
    padding      : 1.25rem;
    background   : #ffffff;
}

.modal_container {
    padding: 0.9375rem;
}

.modal_title {
    display        : flex;
    flex-direction : column;
    justify-content: start;
    align-items    : start;
    font-size      : inherit;
    padding        : 0.3125rem 0;
    text-wrap      : balance;
}

.heading {
    font-size  : 1.000rem;
    font-weight: 700;
}

.modal_title .label {
    padding: 0.3125rem 0 0 0;
}

.modal_items {
    box-sizing: border-box;
    margin-top: 0.625rem;
}



.cards {
    display       : flex;
    flex-direction: column;
    overflow-y    : auto;
    max-height    : calc(70vh - 250px);
    min-height    : 294px;
}

.cards>div {
    margin: 0.3125rem 0;
}

.cards>div:first-of-type {
    margin: 0 0 0.3125rem 0;
}

.cards>div:last-of-type {
    margin: 0.3125rem 0 0 0;
}

/* Стилизация всего скроллбара */
.cards::-webkit-scrollbar {
    width : 12px;
    height: 12px;
}

/* Стилизация трека (фон) */
.cards::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius   : 10px;
}

/* Стилизация самого ползунка */
.cards::-webkit-scrollbar-thumb {
    background-color: #ffba00;
    border-radius   : 10px;
    border          : 3px solid #f0f0f0;
}

/* Стилизация угла между вертикальным и горизонтальным скроллбаром */
.cards::-webkit-scrollbar-corner {
    background-color: #f0f0f0;
}

.modal_buttons_container {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
}

.modal_close_btn {
    background     : #ffba00;
    border-radius  : 50%;
    border         : none;
    font-size      : 30px;
    color          : #080600;
    position       : absolute;
    top            : 7px;
    right          : 7px;
    cursor         : pointer;
    display        : flex;
    justify-content: center;
    align-items    : center;
    z-index        : 1;
    padding        : 5px;
}

.modal_close_btn:hover {
    background-color: #fee38b;
}

.modal_close_btn:disabled {
    background-color: #cccccc;
    cursor          : not-allowed;
}


.card_preloader {
    width          : 100%;
    min-height     : 294px;
    border-radius  : 8px;
    background     : linear-gradient(to right, rgb(149, 149, 149), rgb(201, 201, 201));
    background-size: 400% 400%;
    animation      : gradientAnimation 3s ease infinite;
}

/* Анимация изменения градиента */
@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
        /* Начальная позиция градиента */
    }

    50% {
        background-position: 100% 50%;
        /* Позиция на половине пути */
    }

    100% {
        background-position: 0% 50%;
        /* Возвращаемся в исходное положение */
    }
}

@media (max-width: 405px) {

    .modal,
    .modal_wraper {
        padding: 0;
    }

    .modal_container {
        padding: 2.5rem 0.9375rem 0.9375rem 0.9375rem;
    }

    .modal_buttons_container {
        margin-top    : 1rem;
        flex-direction: column-reverse;
    }
}