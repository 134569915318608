/* Стили для контейнера */
.range_container {
    display       : flex;
    flex-direction: row;
    padding       : 0.625rem 0;
}

/* Стили для метки */
.range_label {
    font-size    : inherit;
    margin-bottom: 0.3125rem;
    position     : relative;
    display      : block;
    line-height  : 1.5;
    color        : #384349;
    text-align   : start;
    width        : 100%;
    text-wrap    : balance;
}

.range_field_container {
    height     : 2.5rem;
    width      : 100%;
    box-sizing : border-box;
    margin-left: 0.625rem;
}

.range_field_container input {
    opacity: 0;
    left   : -9999px !important;
    top    : -9999px !important;
}

.range_field_container input:focus {
    outline: none;
}

/* Стили для отображения текущего значения */
.range_value {
    font-size  : inherit;
    color      : #384349;
    font-weight: 700;
}

.range_field_prefix {
    position: relative;
    float   : left;
}

.key_label {
    display                           : flex;
    justify-content                   : center;
    align-items                       : center;
    width                             : 2.5rem;
    height                            : 2.5rem;
    line-height                       : 2.5rem;
    box-sizing                        : border-box;
    font-size                         : 0.875rem;
    -webkit-transition-property       : color, border-color;
    -moz-transition-property          : color, border-color;
    -ms-transition-property           : color, border-color;
    -o-transition-property            : color, border-color;
    transition-property               : color, border-color;
    -webkit-transition-duration       : 0.25s;
    -moz-transition-duration          : 0.25s;
    -ms-transition-duration           : 0.25s;
    -o-transition-duration            : 0.25s;
    transition-duration               : 0.25s;
    -webkit-transition-timing-function: ease-in-out;
    -moz-transition-timing-function   : ease-in-out;
    -ms-transition-timing-function    : ease-in-out;
    -o-transition-timing-function     : ease-in-out;
    transition-timing-function        : ease-in-out;
    -webkit-transition-delay          : 0s;
    -moz-transition-delay             : 0s;
    -ms-transition-delay              : 0s;
    -o-transition-delay               : 0s;
    transition-delay                  : 0s;
    background-color                  : #f9f9f9;
    border                            : 1px solid #dcdcdc;
    color                             : #2d647f;
    text-align                        : center;

    position     : relative;
    margin-top   : 0;
    margin-bottom: 0;
    font-style   : normal;
    font-weight  : 700;
    border-radius: 3px;
    cursor       : default;
}

@media (max-width: 650px) {
    .range_container {
        padding: 1rem 0;
    }

    .range_label {
        margin-bottom: 1rem;
    }

    .range_field_container {
        height: auto;
    }
}

@media (max-width: 300px) {
    .range_field_container {
        height: auto;
    }
}