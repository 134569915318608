.container {
    max-width     : 1360px;
    position      : relative;
    display       : flex;
    flex-direction: row;

    padding         : 1.25rem;
    margin          : 0 auto;
    background-color: #ffffff;
    border-radius   : 8px;
    box-shadow      : 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form_container {
    flex   : 1;
    padding: 0 0.9375rem;
}

.image_container {
    flex           : 2;
    width          : 100%;
    display        : flex;
    justify-content: center;
    align-items    : start;
    padding        : 0 0.9375rem;
    margin-top     : 1.85rem;
}

.image_container img {
    width        : 100%;
    height       : auto;
    border-radius: 3px;
}

.form_buttons_container {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
}

.form_error {
    margin-top    : 1.85rem;
    background    : #ffdada;
    border-radius : 8px;
    display       : flex;
    flex-direction: column;
}

/* Для чередования цветов */
.form_error_item {
    text-align    : start;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    padding       : 1.25rem;
}

.form_error_item:nth-child(even) {
    background: #ffb6b6;
}

.form_error_item:nth-child(even):last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius : 8px;
}

.form_error_icon {
    color           : #ff4848;
    min-width       : 3rem;
    height          : 3rem;
    line-height     : 3rem;
    font-size       : 1.25rem;
    text-align      : center;
    background-color: white;
    border-radius   : 50%;
    margin-right    : 1.25rem;
    display         : block;
}

@media (max-width: 1400px) {
    .form_error_item {
        padding: 0.9375rem;
    }

    .form_error_icon {
        margin-right: 0.9375rem;
    }
}

@media (max-width: 950px) {
    .form_error_item {
        padding: 0.625rem;
    }

    .form_error_icon {
        margin-right: 0.625rem;
    }
}

@media (max-width: 950px) {
    .form_error_item {
        padding: 0.625rem;
    }
}

.form_error_text {
    font-size     : 1rem;
    color         : #000;
    display       : flex;
    flex-direction: column;
}

.form_error_text strong {
    font-weight: 700;
}

.form_error_text span,
.form_error_text strong {
    text-wrap: balance;
}

.error_field label {
    color: #b60000;
}

.error_field input {
    border-color: #ff4848;
}

.error_field input:focus {
    box-shadow: 0 0 0 3px rgba(255, 72, 72, 0.65);
}

.error_field em {
    color           : #da2424;
    background-color: #ffdada;
    border-color    : #ff4848;
}

.error_hightlight {
    position        : relative;
    z-index         : 1;
    background-color: #ff6d6d;
    box-shadow      : 10px 0 0 #ff6d6d, 20px 0 0 #b60000, -20px 0 0 #ff6d6d;
}

@media (max-width: 950px) {
    .container {
        flex-direction: column
    }

    .image_container {
        padding   : 0.3125rem 0;
        margin-top: 0;
    }

    .form_error {
        margin-top: 0;
    }
}

@media (max-width: 500px) {
    .form_container {
        padding: 0;
    }

    .form_buttons_container {
        flex-direction: column
    }
}

@media (max-width: 245px) {
    .container {
        padding: 1rem;
    }

    .form_container {
        padding: 0;
    }
}