.card_link {
    text-decoration: none;
    color          : inherit;
}

.card {
    display       : flex;
    flex-direction: row;
    border        : 1px solid #ddd;
    border-radius : 8px;
    overflow      : hidden;
    background    : #fff;
    cursor        : pointer;
    /* margin        : 0.3125rem 0; */
}

.card_image {
    width     : 50%;
    height    : auto;
    object-fit: cover;
}

.card_info {
    padding        : 16px;
    width          : 50%;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    text-align     : start;
    box-sizing     : border-box;
    text-wrap      : balance;
}

.card_info span {
    margin: 4px 0;
}

@media (max-width: 768px) {
    .card {
        flex-direction: column;
    }

    .card_image {
        width : 100%;
        height: 200px;
    }

    .card_info {
        width: 100%;
    }
}