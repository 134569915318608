.button_wrapper {
    padding: 0.625rem;
}

.button_container {
    padding: 0.3125rem;
}

.button {
    height          : auto;
    border          : none;
    transition      : background-color 0.3s ease;
    position        : relative;
    display         : flex;
    justify-content : center;
    align-items     : center;
    cursor          : pointer;
    background-color: transparent;
    color           : #384349;
    font-size       : 0.850rem;
    font-weight     : 700;
    margin          : auto;
    border-bottom   : 1px solid #b5c5cd;
    padding-left    : 0;
    padding-right   : 0;
}

.button:focus {
    outline: none;
}

.cta {
    background-color: #ffba00;
    color           : #080600;
    border-radius   : 8px;
    padding         : 8px 20px;
    border          : none;
}

.button:hover {
    color     : #28a3de;
    transition: 0.3s;
}

.cta:hover {
    background-color: #fee38b;
    color           : #080600;
    transition      : 0.3s;
}

.button:disabled {
    cursor          : not-allowed;
    color           : #080600;
}

.cta:disabled {
    background-color: #cccccc;
}

.button_icon {
    margin-right   : 3px;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.button_text {
    display: inline-block;
    height : 100%;
}