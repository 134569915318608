.input_container {
    display       : flex;
    flex-direction: column;
    padding       : 0.3125rem 0;
}

.input_container label {
    font-size     : inherit;
    margin-bottom : 0.3125rem;
    padding-right : 1.5625rem;
    position      : relative;
    display       : flex;
    flex-direction: row;
    line-height   : 1.5;
    color         : #384349;
    text-align    : start;
    text-wrap     : balance;
}

.error_icon {
    margin-right: 0.3rem;
}

.input_field_container {
    position      : relative;
    display       : flex;
    flex-direction: row;
}

.input_field {
    -webkit-tap-highlight-color   : transparent;
    -webkit-user-select           : none;
    user-select                   : none;
    position                      : relative;
    padding                       : 0.590125rem 0.625rem;
    display                       : block;
    width                         : 100%;
    color                         : inherit;
    overflow                      : hidden;
    text-overflow                 : ellipsis;
    border                        : 1px solid #dcdcdc;
    border-radius                 : 3px;
    white-space                   : nowrap;
    cursor                        : inherit;
    transition-property           : all;
    transition-duration           : 0.25s;
    transition-timing-function    : ease-in-out;
    transition-delay              : 0s;
    -webkit-appearance            : none;
    -moz-appearance               : none;
    appearance                    : none;
    height                        : 2.5rem;
    /* font-size                  : inherit; */
    font-size                     : 1rem;
    line-height                   : 1;
    outline                       : none;
    border-top-left-radius        : 0;
    border-bottom-left-radius     : 0;
    background                    : #f7f7f8;
    font-weight                   : 700;
    box-sizing                    : border-box;
}

.input_field:focus {
    box-shadow: 0 0 0 3px rgba(74, 178, 227, 0.65);
}

.input_field[type="number"]::-webkit-outer-spin-button,
.input_field[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin            : 0;
}

.input_field[type="number"] {
    -moz-appearance: textfield;
}

.input_field_prefix {
    position  : relative;
    background: #f7f7f8;
    float     : left;
}

.key_label {
    display                           : flex;
    justify-content                   : center;
    align-items                       : center;
    width                             : 2.5rem;
    height                            : 2.5rem;
    line-height                       : 2.5rem;
    box-sizing                        : border-box;
    font-size                         : 0.875rem;
    -webkit-transition-property       : color, border-color;
    -moz-transition-property          : color, border-color;
    -ms-transition-property           : color, border-color;
    -o-transition-property            : color, border-color;
    transition-property               : color, border-color;
    -webkit-transition-duration       : 0.25s;
    -moz-transition-duration          : 0.25s;
    -ms-transition-duration           : 0.25s;
    -o-transition-duration            : 0.25s;
    transition-duration               : 0.25s;
    -webkit-transition-timing-function: ease-in-out;
    -moz-transition-timing-function   : ease-in-out;
    -ms-transition-timing-function    : ease-in-out;
    -o-transition-timing-function     : ease-in-out;
    transition-timing-function        : ease-in-out;
    -webkit-transition-delay          : 0s;
    -moz-transition-delay             : 0s;
    -ms-transition-delay              : 0s;
    -o-transition-delay               : 0s;
    transition-delay                  : 0s;
    background-color                  : #f9f9f9;
    border                            : 1px solid #dcdcdc;
    color                             : #2d647f;
    text-align                        : center;

    position     : relative;
    margin-top   : 0;
    margin-bottom: 0;
    font-style   : normal;
    font-weight  : 700;
    border-radius: 3px;
    cursor       : default;

    border-right-width        : 0;
    border-top-right-radius   : 0;
    border-bottom-right-radius: 0;
}