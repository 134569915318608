.range-slider .range-slider__thumb,
.range-slider .range-slider__range {
    background: #ffba00;
}

.range-slider .range-slider__thumb {
    height: 15px;
    width : 15px;
}

.range-slider .range-slider__range {
    border-radius: 4px;
}

@media (max-width: 650px) {
    .range-slider {
        height: 11px;
    }

    .range-slider .range-slider__thumb {
        height: 25px;
        width : 25px;
    }
}